@import url('https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800');


.rtsMarker{
  height: 30px;
  width:30px;
  cursor:pointer;
  border-radius:15px;
  text-align:center;
  font-weight: bold;
  font-size: 1.5em;
  display:flex;
  justify-content: center;
  align-items: center;
  background-color: gray;
}

.rtsTrackerMarker{
  height: 30px;
  width:30px;
  cursor:pointer;
  border-radius:15px;
  text-align:center;
  font-weight: bold;
  font-size: 1.5em;
  display:flex;
  justify-content: center;
  align-items: center;
  background-color: #96694c;
}

.rtsMarkerSmallInit{
  height: 20px;
  width:20px;
  cursor:pointer;
  border-radius:10px;
  text-align:center;
  font-weight: bold;
  font-size: 1em;
  display:flex;
  justify-content: center;
  align-items: center;
  background-color:gray;
}

.rtsMarkerSmallInitLegend{
  height: 10px;
  width:10px;
  border-radius:5px;
  background-color:gray;
}

.rtsMarkerSmallOnSite{
  height: 20px;
  width:20px;
  cursor:pointer;
  border-radius:10px;
  text-align:center;
  font-weight: bold;
  font-size: 1em;
  display:flex;
  justify-content: center;
  align-items: center;
  background-color:royalblue;
}

.rtsMarkerSmallOnSiteLegend{
  height: 10px;
  width:10px;
  border-radius:5px;
  background-color:royalblue;
}

.rtsMarkerSmallWip{
  height: 20px;
  width:20px;
  cursor:pointer;
  border-radius:10px;
  text-align:center;
  font-weight: bold;
  font-size: 1em;
  display:flex;
  justify-content: center;
  align-items: center;
  background-color: darkgreen;
}

.rtsMarkerSmallWipLegend{
  height: 10px;
  width:10px;
  border-radius:5px;
  background-color:darkgreen;
}

.rtsMarkerSmallRet{
  height: 20px;
  width:20px;
  cursor:pointer;
  border-radius:10px;
  text-align:center;
  font-weight: bold;
  font-size: 1em;
  display:flex;
  justify-content: center;
  align-items: center;
  background-color: #96694c;
}

.rtsMarkerSmallRetLegend{
  height: 10px;
  width:10px;
  border-radius:5px;
  background-color:#96694c;
}


.rtsMarkerSmallCur{
  height: 20px;
  width:20px;
  cursor:pointer;
  border-radius:10px;
  text-align:center;
  font-weight: bold;
  font-size: 1em;
  display:flex;
  justify-content: center;
  align-items: center;
  background-color: red;
}

.rtsMarkerSmallCurLegend{
  height: 10px;
  width:10px;
  border-radius:5px;
  background-color:red;
}







.rtsMarkerSetPrevious{
  height: 20px;
  width:20px;
  cursor:pointer;
  border-radius:10px;
  text-align:center;
  font-weight: bold;
  font-size: 1em;
  display:flex;
  justify-content: center;
  align-items: center;
  background-color: #96694c;
}

.rtsMarkerSetNew{
  height: 20px;
  width:20px;
  cursor:pointer;
  border-radius:10px;
  text-align:center;
  font-weight: bold;
  font-size: 1em;
  display:flex;
  justify-content: center;
  align-items: center;
  background-color: red;
}
